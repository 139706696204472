<template>
  <div class="container">
    <div class="cards">
      <div class="card-item" @click="$router.push('/order')">
        <div class="left">
          <div class="img-bg-green img-box">
            <!-- <img src="https://source.aidogcatpet.com/forit/1.png" alt="" /> -->
            <i class="el-icon-shopping-bag-2"></i>
          </div>
          <div class="number">
            <div class="num">{{ indexData.sum_order_num }}</div>
            <div class="des">总订单数</div>
          </div>
        </div>
        <div class="right">
          <i class="el-icon-warning"></i>
          <div>
            日同比
            <span
              class="up"
              v-if="indexData.day_yoy_sum_order_num_percentage >= 0"
              ><span style="margin: 0 2px">⬆</span
              >{{ indexData.day_yoy_sum_order_num_percentage }}%</span
            >
            <span v-else class="down"
              ><span style="margin: 0 2px">⬇</span
              >{{ indexData.day_yoy_sum_order_num_percentage }}%</span
            >
          </div>
        </div>
      </div>
      <div class="card-item" @click="$router.push('/account')">
        <div class="left">
          <div class="img-bg-green img-box">
            <!-- <img src="https://source.aidogcatpet.com/forit/2.png" alt="" /> -->
            <i class="el-icon-money"></i>
          </div>
          <div class="number">
            <div class="num">￥{{ indexData.sum_order_sales_volume }}</div>
            <div class="des">总销售金额</div>
          </div>
        </div>
        <div class="right">
          <i class="el-icon-warning"></i>
          <div>
            日同比
            <span
              class="up"
              v-if="indexData.day_yoy_sum_order_sales_volume_percentage >= 0"
              ><span style="margin: 0 2px">⬆</span
              >{{ indexData.day_yoy_sum_order_sales_volume_percentage }}%</span
            >
            <span v-else class="down"
              ><span style="margin: 0 2px">⬇</span
              >{{ indexData.day_yoy_sum_order_sales_volume_percentage }}%</span
            >
          </div>
        </div>
      </div>
      <div class="card-item" @click="$router.push('/purchase')">
        <div class="left">
          <div class="img-bg-green img-box">
            <!-- <img src="https://source.aidogcatpet.com/forit/3.png" alt="" /> -->
            <i class="el-icon-house"></i>
          </div>
          <div class="number">
            <div class="num">{{ indexData.sum_purchase_sum_stock }}</div>
            <div class="des">总采购库存</div>
          </div>
        </div>
        <div class="right">
          <i class="el-icon-warning"></i>
          <div>
            日同比
            <span
              class="up"
              v-if="indexData.day_yoy_sum_purchase_sum_stock_percentage >= 0"
              ><span style="margin: 0 2px">⬆</span
              >{{ indexData.day_yoy_sum_purchase_sum_stock_percentage }}%</span
            >
            <span v-else class="down"
              ><span style="margin: 0 2px">⬇</span
              >{{ indexData.day_yoy_sum_purchase_sum_stock_percentage }}%</span
            >
          </div>
        </div>
      </div>
      <!--  -->
      <div class="card-item">
        <div class="left">
          <div class="img-bg-blue img-box">
            <!-- <img src="https://source.aidogcatpet.com/forit/4.png" alt="" /> -->
            <i class="el-icon-bank-card"></i>
          </div>
          <div class="number">
            <div class="num">￥{{ indexData.sum_commission }}</div>
            <div class="des">总销售佣金</div>
          </div>
        </div>
        <div class="right">
          <i class="el-icon-warning"></i>
          <div>
            日同比
            <span
              class="up"
              v-if="indexData.day_yoy_sum_commission_percentage >= 0"
              ><span style="margin: 0 2px">⬆</span
              >{{ indexData.day_yoy_sum_commission_percentage }}%</span
            >
            <span v-else class="down"
              ><span style="margin: 0 2px">⬇</span
              >{{ indexData.day_yoy_sum_commission_percentage }}%</span
            >
          </div>
        </div>
      </div>
      <div class="card-item">
        <div class="left">
          <div class="img-bg-blue img-box">
            <!-- <img src="https://source.aidogcatpet.com/forit/5.png" alt="" /> -->
            <i class="el-icon-coin"></i>
          </div>
          <div class="number">
            <div class="num">￥{{ indexData.sum_promotion }}</div>
            <div class="des">总推广奖励</div>
          </div>
        </div>
        <div class="right">
          <i class="el-icon-warning"></i>
          <div>
            日同比
            <span
              class="up"
              v-if="indexData.day_yoy_sum_promotion_percentage >= 0"
              ><span style="margin: 0 2px">⬆</span
              >{{ indexData.day_yoy_sum_promotion_percentage }}%</span
            >
            <span v-else class="down"
              ><span style="margin: 0 2px">⬇</span
              >{{ indexData.day_yoy_sum_promotion_percentage }}%</span
            >
          </div>
        </div>
      </div>
      <div class="card-item">
        <div class="left">
          <div class="img-bg-blue img-box">
            <!-- <img src="https://source.aidogcatpet.com/forit/6.png" alt="" /> -->
            <i class="el-icon-wallet"></i>
          </div>
          <div class="number">
            <div class="num">￥{{ indexData.sum_rebate }}</div>
            <div class="des">总销售返点</div>
          </div>
        </div>
        <div class="right">
          <i class="el-icon-warning"></i>
          <div>
            日同比
            <span class="up" v-if="indexData.day_yoy_sum_rebate_percentage >= 0"
              ><span style="margin: 0 2px">⬆</span
              >{{ indexData.day_yoy_sum_rebate_percentage }}%</span
            >
            <span v-else class="down"
              ><span style="margin: 0 2px">⬇</span
              >{{ indexData.day_yoy_sum_rebate_percentage }}%</span
            >
          </div>
        </div>
      </div>
      <!--  -->
      <div class="card-item" @click="todayOrderJump">
        <div class="left">
          <div class="img-bg-orange img-box">
            <!-- <img src="https://source.aidogcatpet.com/forit/7.png" alt="" /> -->
            <i class="el-icon-date"></i>
          </div>
          <div class="number">
            <div class="num">{{ indexData.today_order_num }}</div>
            <div class="des">今日订单数</div>
          </div>
        </div>
        <div class="right">
          <i class="el-icon-warning"></i>
          <div>
            日同比
            <span
              class="up"
              v-if="indexData.day_yoy_today_order_num_percentage >= 0"
              ><span style="margin: 0 2px">⬆</span
              >{{ indexData.day_yoy_today_order_num_percentage }}%</span
            >
            <span v-else class="down"
              ><span style="margin: 0 2px">⬇</span
              >{{ indexData.day_yoy_today_order_num_percentage }}%</span
            >
          </div>
        </div>
      </div>
      <div
        class="card-item"
        @click="$router.push({ name: 'order', params: { status: 2 } })"
      >
        <div class="left">
          <div class="img-bg-orange img-box">
            <!-- <img src="https://source.aidogcatpet.com/forit/8.png" alt="" /> -->
            <i class="el-icon-time"></i>
          </div>
          <div class="number">
            <div class="num">{{ indexData.wait_delivery_order_num }}</div>
            <div class="des">待发货订单</div>
          </div>
        </div>
        <div class="right">
          <i class="el-icon-warning"></i>
          <div>
            日同比
            <span
              class="up"
              v-if="indexData.day_yoy_wait_delivery_order_num_percentage >= 0"
              ><span style="margin: 0 2px">⬆</span
              >{{ indexData.day_yoy_wait_delivery_order_num_percentage }}%</span
            >
            <span v-else class="down"
              ><span style="margin: 0 2px">⬇</span
              >{{ indexData.day_yoy_wait_delivery_order_num_percentage }}%</span
            >
          </div>
        </div>
      </div>
      <div
        class="card-item"
        @click="$router.push({ name: 'order', params: { status: 3 } })"
      >
        <div class="left">
          <div class="img-bg-orange img-box">
            <!-- <img src="https://source.aidogcatpet.com/forit/9.png" alt="" /> -->
            <i class="el-icon-truck"></i>
          </div>
          <div class="number">
            <div class="num">{{ indexData.already_delivery_order_num }}</div>
            <div class="des">已发货订单</div>
          </div>
        </div>
        <div class="right">
          <i class="el-icon-warning"></i>
          <div>
            日同比
            <span
              class="up"
              v-if="
                indexData.day_yoy_already_delivery_order_num_percentage >= 0
              "
              ><span style="margin: 0 2px">⬆</span
              >{{
                indexData.day_yoy_already_delivery_order_num_percentage
              }}%</span
            >
            <span v-else class="down"
              ><span style="margin: 0 2px">⬇</span
              >{{
                indexData.day_yoy_already_delivery_order_num_percentage
              }}%</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="chart">
      <div class="commissions-trend">
        <div class="title">
          <div class="left">销售佣金趋势</div>
          <div class="right">
            <el-select v-model="salesCommissionType" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div id="main" ref="main"></div>
      </div>
    </div>

    <div class="chart2">
      <div class="commissions-trend2 item">
        <div class="title">
          <div class="left">销售额趋势</div>
          <div class="right">
            <el-select v-model="orderSalesAmountType" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div id="main2" ref="main2"></div>
      </div>
    </div>
    <div class="chart2">
      <div class="commissions-trend3 item">
        <div class="title">
          <div class="left">销售订单</div>
          <div class="right">
            <el-select v-model="orderNumType" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div id="main3" ref="main3"></div>
      </div>
    </div>
    <div class="hot-product">
      <el-table :data="hotProductList" stripe style="width: 100%">
        <el-table-column prop="store_name" width="450" label="热销商品">
        </el-table-column>
        <el-table-column prop="order_number" label="周订单量">
        </el-table-column>
        <el-table-column prop="user_name" label="用户名"> </el-table-column>
        <el-table-column prop="brand_name" label="品牌类型"> </el-table-column>
        <el-table-column prop="mini_on_sale_value" label="商品状态">
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="productVisible"
      width="40%"
      :before-close="handleClose"
    >
      <el-image src="https://source.aidogcatpet.com/forit/product_act.jpg"></el-image>
      <span slot="footer" class="dialog-footer">
        <el-button @click="productVisible = false">取 消</el-button>
        <el-button type="primary" @click="productVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts"; // 导入EChats
import { formatDateTime } from "@/utils/util";
export default {
  name: "home", 
  data() {
    return {
      productVisible: true,
      options: [
        {
          value: "1",
          label: "7天",
        },
        {
          value: "2",
          label: "半个月",
        },
        {
          value: "3",
          label: "月",
        },
        {
          value: "4",
          label: "季",
        },
        {
          value: "5",
          label: "年",
        },
      ],
      indexData: {},
      salesCommissionType: "1",
      orderSalesAmountType: "1",
      orderNumType: "1",
      announcementList: [],
      page: 1,
      dialogVisible: false,
      limit: 10,
      hotProductList: [],
    };
  },
  watch: {
    salesCommissionType(newName, oldName) {
      this.getSalesCommissionTrend();
    },
    orderSalesAmountType(newName, oldName) {
      this.getOrderSalesAmountTrend();
    },
    orderNumType(newName, oldName) {
      this.getOrderNumTrend();
    },
  },
  computed: {},
  mounted() {
    this.getIndexData();
    this.getHotProductList();
    this.getSalesCommissionTrend();
    this.getOrderSalesAmountTrend();
    this.getOrderNumTrend();
  },
  methods: {
    todayOrderJump() {
      // 获取今天的日期
      let today = new Date();
      let todayStr = today.toISOString().split("T")[0];
      // 获取今天的 00:00:00
      let date1 = `${todayStr} 00:00:00`;
      // 获取今天的 23:59:59
      let date2 = `${todayStr} 23:59:59`;
      this.$router.push({ name: "order", params: { value1: [date1, date2] } });
    },

    getSalesCommissionTrend() {
      let myChart = echarts.getInstanceByDom(this.$refs.main);
      if (myChart == null) {
        myChart = echarts.init(this.$refs.main);
      }
      var option;
      this.$http2
        .get("/merapi/shop/getSalesCommissionTrend", {
          params: {
            type: this.salesCommissionType,
          },
        })
        .then((res) => {
          option = {
            xAxis: {
              type: "category",
              axisLine: {
                lineStyle: {
                  color: "#999",
                },
              },
            },
            yAxis: {
              type: "value",
              name: "￥金额",
            },
            series: [
              {
                data: res.data.data,
                type: "bar",
                color: "#81b337",
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: "top", //在上方显示
                      textStyle: {
                        //数值样式
                        color: "black",
                        fontSize: 16,
                      },
                      formatter: function (params) {
                        return `¥${params.data[1]}`; // 自定义格式化函数，将数值前面加上 "¥" 符号
                      },
                    },
                  },
                },
              },
            ],
            emphasis: { label: { show: true, position: "top" } },
          };
          option && myChart.setOption(option);
        });
    },
    getOrderSalesAmountTrend() {
      let myChart = echarts.getInstanceByDom(this.$refs.main2);
      if (myChart == null) {
        myChart = echarts.init(this.$refs.main2);
      }
      var option;
      this.$http2
        .get("/merapi/shop/getOrderSalesAmountTrend", {
          params: {
            type: this.orderSalesAmountType,
          },
        })
        .then((res) => {
          option = {
            xAxis: {
              type: "category",
              axisLine: {
                lineStyle: {
                  color: "#999",
                },
              },
            },
            yAxis: {
              type: "value",
              name: "￥金额",
            },
            series: [
              {
                data: res.data.data,
                type: "bar",
                color: "#1684fc",
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: "top", //在上方显示
                      textStyle: {
                        //数值样式
                        color: "black",
                        fontSize: 16,
                      },
                      formatter: function (params) {
                        return `¥${params.data[1]}`; // 自定义格式化函数，将数值前面加上 "¥" 符号
                      },
                    },
                  },
                },
              },
            ],
            emphasis: { label: { show: true, position: "top" } },
          };
          option && myChart.setOption(option);
        });
    },
    getOrderNumTrend() {
      let myChart = echarts.getInstanceByDom(this.$refs.main3);
      if (myChart == null) {
        myChart = echarts.init(this.$refs.main3);
      }
      var option;
      this.$http2
        .get("/merapi/shop/getOrderNumTrend", {
          params: {
            type: this.orderNumType,
          },
        })
        .then((res) => {
          console.log(res.data.data.list);
          option = {
            xAxis: {
              type: "category",
              axisLine: {
                lineStyle: {
                  color: "#999",
                },
              },
            },
            yAxis: {
              type: "value",
              name: "数量",
            },
            series: [
              {
                data: res.data.data.list,
                type: "line",
                color: "#1684fc",
                areaStyle: {
                  shadowColor: "#d0e6fe",
                },
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: "top", //在上方显示
                      textStyle: {
                        //数值样式
                        color: "black",
                        fontSize: 16,
                      },
                    },
                  },
                },
              },
            ],
            emphasis: { label: { show: true, position: "top" } },
          };
          option && myChart.setOption(option);
        })
        .catch((err) => {});
    },
    getIndexData() {
      // 获取首页数据
      this.$http2
        .get("/merapi/shop/getOverView")
        .then((res) => {
          this.indexData = res.data.data;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    getHotProductList() {
      // 获取热销商品列表
      this.$http2
        .get("/merapi/shop/getShopHotSalesProduct", {
          params: {
            page: 1,
            limit: 5,
          },
        })
        .then((res) => {
          this.hotProductList = res.data.data;
        });
    },
  },
};
</script>

<style scoped lang="less">
.container {
  padding-bottom: 30px;

  .cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: space-between;
  }

  .card-item {
    height: 180px;
    width: 30%;
    padding: 20px;
    margin-bottom: 30px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 4px 12px 0px rgba(84, 95, 167, 0.1);
    border: 0px solid rgba(239, 242, 245, 1);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .number {
        margin-left: 20px;
        height: inherit;

        .num {
          font-size: 26px;
          font-weight: 600;
          color: #101010;
        }

        .des {
          font-size: 16px;
          color: #75848c;
        }
      }
    }

    .right {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      i {
        color: #d2d6d9;
        font-size: 24px;
      }

      div {
        font-size: 12px;
        // font-weight: 600;
        color: #75848c;

        .up {
          color: #13bda0;
        }
        .down {
          color: #d91d13;
        }
      }
    }
    .img-bg-green {
      background-color: #81b337;
    }
    .img-bg-blue {
      background-color: #0078fb;
    }
    .img-bg-orange {
      background-color: #f89044;
    }
    .img-box {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 36px;
        color: #fff;
      }
    }
    img {
      width: 45px;
      height: 45px;
    }

    .iconfont {
      font-size: 40px;
      margin-left: 40px;
    }
  }

  .el-dialog {
    .el-dialog__header {
      display: flex;
      justify-content: center;
    }

    .content {
      // text-align: center;
      .title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .message {
        font-size: 18px;
      }
    }
  }

  .chart {
    .commissions-trend {
      position: relative;
      width: 100%;
      height: 320px;
      padding-top: 80px;
      background-color: #fff;
      .title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 60px;
        .left {
          font-weight: 600;
        }
        .right .el-select {
          width: 120px;
        }
      }
    }
    #main {
      width: 100%;
      height: 100%;
    }
  }
  .chart2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    .item {
      position: relative;
      height: 320px;
      padding-top: 80px;
      background-color: #fff;
      .title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 60px;
        .left {
          font-weight: 600;
        }
        .right .el-select {
          width: 120px;
        }
      }
    }
    .commissions-trend2 {
      width: 100%;
    }
    .commissions-trend3 {
      width: 100%;
    }
    #main2 {
      width: 100%;
      height: 100%;
    }
    #main3 {
      width: 100%;
      height: 100%;
    }
  }
  .hot-product {
    margin-top: 30px;
  }
}
</style>
