<template>
  <div class="container">
    <div class="export">
      <div class="input">
        <div>关键词：</div>
        <el-input v-model="input" placeholder="商品条码/商品名称"></el-input>
      </div>
      <el-button type="primary" @click="getProductList('nopage')"
        >查询</el-button
      >
    </div>
    <div class="table">
      <el-table
        border
        :data="productList"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="cell"
        stripe
        style="width: 100%"
      >
        <!-- <el-table-column prop="id" width="80" label="编号"></el-table-column> -->
        <el-table-column prop="store_name" label="组合名称"></el-table-column>
        <el-table-column prop="bar_code" label="组合码"></el-table-column>

        <el-table-column prop="head_img_url" width="170" label="图片">
          <template slot-scope="scope">
            <el-popover placement="right" width="400" trigger="click">
              <img
                :src="scope.row.image"
                style="width: 400px; height: 400px"
                class="enlarge"
              />
              <el-button slot="reference" style="border: 0; padding: 0">
                <img :src="scope.row.image" class="head_pic" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="small_pifa_price"
          label="代发价"
        ></el-table-column>
        <el-table-column prop="ls_price" label="售卖价"></el-table-column>
    
        <el-table-column prop="sex" label="商品" width="600">
          <template slot-scope="scope">
            <el-table :data="scope.row.details" style="width: 100%">
              <el-table-column width="160" prop="bar_code" label="条码">
              </el-table-column>
              <el-table-column prop="number" label="数量"> </el-table-column>
              <el-table-column prop="combine_price" label="代发价">
              </el-table-column>
              <el-table-column prop="combine_sale_price" label="售卖价">
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background
        :hide-on-single-page="false"
        :current-page="page"
        @current-change="curretnChange"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 40]"
        @prev-click="curretnChange"
        @next-click="curretnChange"
        layout="total,prev,sizes, pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
  
  <script>
import { urlEncode } from "@/utils/util.js";
import { BASE_URL2 } from "../request/request.config";
export default {
  name: "product",
  data() {
    return {
      input: "",
      page: 1,
      limit: 10,
      total: 0,
      productList: [],
    };
  },
  watch: {},
  computed: {
    shopInfo: {
      get() {
        return JSON.parse(window.localStorage.getItem("shopInfo")) || {};
      },
    },
  },
  created() {
    this.getProductList();
  },
  methods: {
    cell({ row, column, columnIndex }) {
      return { textAlign: "center" };
    },
    loadFM() {
      window.open(
        "https://source.aidogcatpet.com/saas/Fromm-晟煜寵物 -销售控价表.pdf"
      );
    },
    loadHY() {
      window.open(
        "https://source.aidogcatpet.com/saas/荒野盛宴-鴻業寵物-销售控价表.pdf"
      );
    },
    prevClick(e) {
      // 点击上一页
      this.page = e;
      this.getProductList();
    },
    nextClick(e) {
      // 点击下一页
      this.page = e;
      this.getProductList();
    },
    curretnChange(e) {
      // 切换page
      this.page = e;
      this.getProductList();
    },

    handleSizeChange(e) {
      this.limit = e;
      this.getProductList();
    },

    getProductList(e) {
      var keyword = this.input;
      this.page = e === "nopage" ? 1 : this.page;
      var page = this.page;
      var limit = this.limit;
      this.$http2
        .get("/merapi/product/combine_list", {
          params: {
            keyword,
            page,
            limit,
          },
        })
        .then((res) => {
          console.log(res);
          this.productList = res.data.data.list;
          this.total = res.data.data.count;
        })
        .catch((err) => {
          this.$message.error(err + "");
        });
    },
  },
};
</script>
  
  <style scoped lang="less">
.select {
  display: flex;
  align-items: center;

  // justify-content: space-between;
  .brand {
    margin-right: 30px;
  }

  div {
    font-size: 16px;
  }
}

.htmlContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgs {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;

  img {
    width: 90%;
  }
}

.head_pic {
  width: 120px;
  height: 120px;
}
.tag {
  display: inline-block;
  background-color: #66b1ff;
  color: #fff;
  padding: 0px 8px;
  font-size: 12px;
  margin-left: 10px;
  border-radius: 8px;
}
.item-group {
  height: 140px;
  display: flex;
  padding-left: 20px;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.export {
  display: flex;
  align-items: center;
  margin-top: 20px;
  .el-button {
    background-color: #0078fb;
  }
  .input {
    display: flex;
    align-items: center;

    div {
      width: 100px;
      font-size: 16px;
    }

    .el-input {
      width: 300px;
      font-size: 14px;
    }
  }

  .el-button {
    margin-left: 40px;
  }
}

.loadBox {
  margin-left: 100px;
  display: flex;
  // flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.table {
  margin-top: 30px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 30px 0;

  align-items: center;
}
</style>
  